import { React, useState, useContext, AppContext, OldRow, useRef, InfoTextArea, ErrorTextArea } from "V3";
import "./FinSliderInput.scss";

export const FinSliderInput = (props) => {
    const { isDarkMode } = useContext(AppContext);
    const { root, name, setStore, disableControl } = props;
    const [inputView, setInputView] = useState(false);
    const [onFocus, setOnFocus] = useState(true);
    const inputArea = useRef();

    /**
     * onChange Handler
     */
    const onChangeHandler = (val) => {
        if (props.disableControl) return;
        setStore(val, { name: name });
    };

    /**
     * Return
     */
    return (
        <OldRow {...props}>
            <div
                className={
                    "FinSliderInput_frame" +
                    (isDarkMode ? "" : " light") +
                    (!root[name] ? " false" : "") +
                    (disableControl ? " disabled" : "")
                }
                onMouseEnter={() => setOnFocus(true)}
                onMouseLeave={() => setOnFocus(false)}
            >
                <input
                    className={"FinSliderInput_range" + (isDarkMode ? "" : " light")}
                    type="range"
                    value={root[name] || 0}
                    min="0"
                    max="100"
                    onChange={(e) => onChangeHandler(e.target.value)}
                    // onMouseMove={(e) => onChangeHandler(e.target.value) }
                />
                {!inputView || disableControl ? (
                    <span onClick={() => setInputView(true)}>{root[name]}</span>
                ) : (
                    <input
                        className={"FinSliderInput_inputArea" + (isDarkMode ? "" : " light")}
                        ref={inputArea}
                        value={root[name] || 0}
                        onBlur={() => setInputView(false)}
                        onChange={(e) => onChangeHandler(e.target.value)}
                        spellCheck="false"
                        autoCorrect="off"
                        autoCapitalize="off"
                    />
                )}
            </div>
            <InfoTextArea onFocus={onFocus} {...props} />
            <ErrorTextArea onFocus={onFocus} {...props} />
        </OldRow>
    );
};
