import { React, Fragment, Icon, IconLib, useState, _, useContext, AppContext, indexOf_ObjsInArr } from "V3";
import "./SelectBoxMultipleSelectionsList.scss";

export const SelectBoxMultipleSelectionsList = (props) => {
    if (!props.thisData || props.thisData.length < 1) return null;
    return (
        <Fragment>
            {props.thisData.map((item, key) => {
                return <SingleRow item={item} key={key} {...props} />;
            })}
        </Fragment>
    );
};

const SingleRow = ({ item, ...props }) => {
    const { isDarkMode } = useContext(AppContext);
    const [onHover, setOnHover] = useState(false);

    /**
     * Remove handler
     */
    const removeHandler = (item) => {
        let newOptions = _.cloneDeep(props.thisData || []);
        newOptions.splice(indexOf_ObjsInArr(props.thisData, item), 1);
        props.setStore((prev) => ({
            ...prev,
            [props.name]: newOptions,
        }));
    };

    /**
     * Return
     */
    return (
        <div className={"SelectBoxMultipleSelectionsList_row" + (onHover ? " deleteHover" : "")}>
            <div className="SelectBoxMultipleSelectionsList_text">{item.name || item.label || "Und."}</div>
            <div
                className="SelectBoxMultipleSelectionsList_icon"
                onMouseEnter={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
                onClick={() => removeHandler(item)}
            >
                <Icon icon={IconLib.IconTrash} color={isDarkMode || onHover ? "white" : "red"} width={20} />
            </div>
        </div>
    );
};
