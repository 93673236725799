import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useContext, AppContext } from "V3";

const NestedTable = ({ data, loading }) => {
    const { isDarkMode } = useContext(AppContext);
    const { metadata } = useSelector((state) => state);
    const { data: meta } = metadata || {};
    const headers = [
        "Geo",
        "Integration",
        "Deal Type",
        "Deal Revenue Type",
        "Deal",
        "Lead Cap",
        "Block Waterfall",
        "Weight",
    ];

    const getCountryName = (id) => {
        return meta?.geos?.find((g) => g.id === id)?.country || "Unknown";
    };

    const getIntegrationName = (id) => {
        return meta?.integrations?.find((g) => g.id === id)?.name || "Unknown";
    };

    return (
        <Table size="small" aria-label="nested table">
            <TableHead>
                <TableRow style={{ backgroundColor: isDarkMode ? "#21222a" : "#f2f2f2" }}>
                    {headers.map((header) => (
                        <TableCell key={header}>{header}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.length > 0 ? (
                    data.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{getCountryName(item.geo)}</TableCell>
                            <TableCell>{getIntegrationName(item.integration)}</TableCell>
                            <TableCell>{item.dealType || "-"}</TableCell>
                            <TableCell>{item.dealRevenueType || "-"}</TableCell>
                            <TableCell>{item.deal}</TableCell>
                            <TableCell>{item.leadCap}</TableCell>
                            <TableCell>{item.waterfallBlocked?.toString() || "-"}</TableCell>
                            <TableCell>{item.weight || "-"}</TableCell>
                        </TableRow>
                    ))
                ) : loading ? (
                    <TableRow>
                        <TableCell colSpan={6} align="center">
                            <Typography variant="subtitle1" color="textSecondary">
                                Loading ...
                            </Typography>
                        </TableCell>
                    </TableRow>
                ) : (
                    <TableRow>
                        <TableCell colSpan={6} align="center">
                            <Typography variant="subtitle1" color="textSecondary">
                                No data.
                            </Typography>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

export default NestedTable;
