import React from "react";
import { AppContext, useContext } from "V3";
const LightTheme = React.lazy(() => import("./../scss/LightTheme"));
const DarkTheme = React.lazy(() => import("./../scss/DarkTheme"));
const ThemeSelector = ({ children }) => {

    const { isDarkMode } = useContext(AppContext);
    const CHOSEN_THEME = isDarkMode ? "dark" : "light";
    return (
        <>
            <React.Suspense fallback={<></>}>{CHOSEN_THEME === "dark" ? <DarkTheme /> : <LightTheme />}</React.Suspense>
            {children}
        </>
    );
};

export default ThemeSelector;
