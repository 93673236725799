import { React, Fragment, Icon, IconLib, useState, _, FinToggleSmall } from "V3";
import { FinSliderInput, useEffect, useContext, AppContext, indexOf_ObjsInArr, AutoComplete } from "V3";
import EditIcon from "components/EditIcon";
import CIcon from "@coreui/icons-react";
import { useParams } from "react-router-dom";
import "./SelectBoxMultipleAllocationList.scss";

export const SelectBoxMultipleAllocationList = (props) => {
    const { store, name, setStore, eplToggle, options, isNativeBox, isNativeBanner, isReorderingLocked } = props;
    const thisData = store && store[name] ? store[name] : [];
    const [remainingData, setRemainingData] = useState(thisData);
    const [btwText, setBtwText] = useState(100);

    /**
     * Reset optionWeight when new row arrives.
     */
    useEffect(() => {
        if (!thisData) return;
        if (thisData.length === remainingData.length) return;
        setRemainingData(thisData);

        // check if the list has a new item without weight
        // and check if the total weight is not equal to 100

        let total = 0;
        let withoutWeight = 0;
        _.forEach(thisData, (it) => {
            if (!it.weight) withoutWeight = withoutWeight + 1;
            total = total + (Number(it.weight) || 0);
        });
        if (total === 100 && withoutWeight === 0) return;

        let newThisData = _.cloneDeep(thisData);
        let remaining = 100;
        const jointWeight = Math.floor(100 / thisData.length);

        for (let i in newThisData) {
            newThisData[i] = {
                ...newThisData[i],
                lock: false,
                weightOverride: false,
                weight: Number(i) === newThisData.length - 1 ? remaining : jointWeight,
            };
            remaining = remaining - jointWeight;
        }
        if (_.isEqual(thisData, newThisData)) return;
        setStore((prev) => ({
            ...prev,
            [name]: newThisData,
        }));

        setBtwText(100);
    }, [thisData, eplToggle]);

    function swapPositions(data, name, y, x) {
        let b = data[y];
        data[y] = data[x];
        data[x] = b;
        setStore((prev) => ({
            ...prev,
            [name]: data,
        }));
    }

    const adjustPosition = (y, x) => {
        if (isReorderingLocked === true) {
            if (name === "preLanderCampaigns") {
                const thatData = store && store["offerLanderCampaigns"] ? store["offerLanderCampaigns"] : [];

                swapPositions(thisData, name, y, x);
                swapPositions(thatData, "offerLanderCampaigns", y, x);
            } else if (name === "offerLanderCampaigns") {
                const thatData = store && store["preLanderCampaigns"] ? store["preLanderCampaigns"] : [];

                swapPositions(thisData, name, y, x);
                swapPositions(thatData, "preLanderCampaigns", y, x);
            }
        } else {
            swapPositions(thisData, name, y, x);
        }
    };

    /**
     * Return
     */

    if (!thisData || thisData.length < 1) return null;
    return (
        <Fragment>
            {!thisData
                ? null
                : thisData.map((item, key) => {
                      return (
                          <SingleRow
                              item={item}
                              key={key}
                              index={key}
                              btwText={btwText}
                              setBtwText={setBtwText}
                              thisData={thisData}
                              eplToggle={eplToggle}
                              options={options}
                              isNativeBox={isNativeBox}
                              isNativeBanner={isNativeBanner}
                              adjustPosition={adjustPosition}
                              {...props}
                          />
                      );
                  })}
        </Fragment>
    );
};

const SingleRow = (props) => {
    const {
        setBtwText,
        item,
        index,
        store,
        name,
        setStore,
        eplToggle,
        options,
        ctaLabels,
        isNativeBox,
        isNativeBanner,
        thisData,
        adjustPosition,
    } = props || {};
    const params = useParams();
    const root = store[name][index];
    const [onDeleteHover, setOnDeleteHover] = useState(false);
    const [onEditHover, setOnEditHover] = useState(false);
    const { isDarkMode } = useContext(AppContext);

    const makePosIcons = (index) => {
        if (thisData.length === 1) {
            return <Fragment></Fragment>;
        }
        if (index === 0) {
            return (
                <>
                    <CIcon
                        style={{
                            color: "darkgray",
                            cursor: "pointer",
                            marginRight: "5px",
                        }}
                        name="cil-arrow-bottom"
                        height={20}
                        onClick={() => {
                            adjustPosition(0, 1);
                        }}
                    />
                </>
            );
        } else if (index === thisData.length - 1) {
            return (
                <CIcon
                    style={{
                        color: "darkgray",
                        cursor: "pointer",
                        marginRight: "5px",
                    }}
                    name="cil-arrow-top"
                    height={20}
                    onClick={() => {
                        adjustPosition(thisData.length - 1, thisData.length - 2);
                    }}
                />
            );
        } else {
            return (
                <Fragment>
                    <CIcon
                        style={{
                            color: "darkgray",
                            cursor: "pointer",
                            marginRight: "5px",
                        }}
                        name="cil-arrow-bottom"
                        height={20}
                        onClick={() => {
                            adjustPosition(index, index + 1);
                        }}
                    />
                    <CIcon
                        style={{
                            color: "darkgray",
                            cursor: "pointer",
                            marginRight: "5px",
                        }}
                        name="cil-arrow-top"
                        height={20}
                        onClick={() => {
                            adjustPosition(index, index - 1);
                        }}
                    />
                </Fragment>
            );
        }
    };

    /**
     * SubUpdateParent for Togglers
     */
    const subUpdateParent = (val, props) => {
        let newoptionWeight = _.cloneDeep(store[name]);
        newoptionWeight[index][props.name] = val;
        setStore((prev) => ({
            ...prev,
            [name]: newoptionWeight,
        }));

        if (eplToggle) {
            const overrideWeightsTotal = calculateOverrideWeightsTotal(newoptionWeight);
            setBtwText(100 - overrideWeightsTotal);
        }
    };

    /**
     * SubUpdateParent for Slider Input
     */
    const subUpdateParentForSlider = (val) => {
        let newoptionWeight = _.cloneDeep(store[name]);
        let incomingVal = val; // 62
        const unlockedItems = _.pickBy(newoptionWeight, (value, key) => !value.lock);
        const unlockedKeys = Object.keys(unlockedItems);
        const lockedWeightsTotal = calculateLockedWeightsTotal(newoptionWeight);
        let remaining = 100 - lockedWeightsTotal;
        const maxValueForEach = remaining - (unlockedKeys.length - 1);
        const minValueForEach = unlockedKeys.length === 1 ? remaining : 1;
        if (incomingVal > maxValueForEach) incomingVal = maxValueForEach;
        if (incomingVal < minValueForEach) incomingVal = minValueForEach;
        remaining = remaining - incomingVal;
        const jointWeight = Math.floor(remaining / (unlockedKeys.length - 1));

        const updater = (val, index) => {
            newoptionWeight[index].weight = Number(val);
        };
        // update focus item first
        const focusIndexOf = unlockedKeys.indexOf(index + "");
        updater(incomingVal, index);
        unlockedKeys.splice(focusIndexOf, 1);
        // then calculate others
        for (let i = 0; i < unlockedKeys.length; i++) {
            const forName = Number(unlockedKeys[i]);
            if (Number(i) === unlockedKeys.length - 1) {
                // update last item
                updater(remaining, forName);
            } else {
                updater(jointWeight, forName);
                remaining = remaining - jointWeight;
            }
        }

        setStore((prev) => ({
            ...prev,
            [name]: newoptionWeight,
        }));

        if (eplToggle) {
            const overrideWeightsTotal = calculateOverrideWeightsTotal(newoptionWeight);
            setBtwText(100 - overrideWeightsTotal);
        }
    };

    /**
     * Remove handler
     */
    const removeHandler = (item) => {
        let newOptions = _.cloneDeep(store[name] || []);
        newOptions.splice(indexOf_ObjsInArr(store[name], item), 1);
        setStore((prev) => ({
            ...prev,
            [name]: newOptions,
        }));
    };

    /**
     * Find Lander name via options if the api doesn't include name
     */
    const findLanderName = (id) => {
        const findIdInsideOptions = _.findIndex(options, (o) => o.id === id);
        if (findIdInsideOptions > -1) {
            const landerName = options[findIdInsideOptions].name || "Deleted campaign";
            return landerName;
        }
        return "Deleted campaign";
    };

    /**
     * Update Cta Label
     */
    const updateCtaLabel = (val) => {
        let newLabel = _.cloneDeep(store[name]);
        const updater = (val, index) => {
            newLabel[index].ctaLabel = val;
        };
        updater(val, index);
        setStore((prev) => ({
            ...prev,
            [name]: newLabel,
        }));
    };

    const getHover = () => {
        if (onDeleteHover) {
            if (!isDarkMode) {
                return " deleteHover2";
            } else return " deleteHover";
        } else if (onEditHover) {
            return " editHover";
        } else return "";
    };

    /**
     * Return
     */
    return (
        <div className={"SelectBoxMultipleAllocationList_row" + getHover()}>
            <div className="SelectBoxMultipleAllocationList_text">
                {item?.name /*|| item.label*/ || findLanderName(item?.id)}
            </div>
            {((!isNativeBox && !isNativeBanner) || isNativeBanner) && (
                <div className="SelectBoxMultipleAllocationList_toggleSmall">
                    {eplToggle ? (
                        <FinToggleSmall
                            toggleText="Override"
                            root={root}
                            name="weightOverride"
                            store={store}
                            setStore={subUpdateParent}
                        />
                    ) : (
                        <FinToggleSmall
                            toggleText="Lock"
                            root={root}
                            name="lock"
                            store={store}
                            setStore={subUpdateParent}
                        />
                    )}
                </div>
            )}
            {((!isNativeBox && !isNativeBanner) || isNativeBanner) && (
                <div className="SelectBoxMultipleAllocationList_sliderArea">
                    {eplToggle ? (
                        root.weightOverride ? (
                            <FinSliderInput
                                name="weight"
                                root={root}
                                store={store}
                                setStore={subUpdateParentForSlider}
                            />
                        ) : (
                            <div style={{ width: 270 }}>{params.id && `Currently: 1 ~${item.weight}`}</div>
                        )
                    ) : (
                        <FinSliderInput
                            name="weight"
                            disableControl={root.lock ? true : false}
                            root={root}
                            store={store}
                            setStore={subUpdateParentForSlider}
                        />
                    )}
                </div>
            )}
            <div>{isNativeBox && makePosIcons(index)}</div>
            {name === "offerLanderCampaigns" && ctaLabels && ctaLabels.length > 0 ? (
                <div className="SelectBoxMultipleAllocationList_ctaLabel">
                    <AutoComplete
                        label="CTA Label"
                        name="ctaLabel"
                        state={root}
                        options={ctaLabels}
                        onChange={updateCtaLabel}
                        getOptionValue={(option) => option}
                        isOptionEqualToValue={(option, value) => option === value}
                    />
                </div>
            ) : null}
            <div className="SelectBoxMultipleAllocationList_icon">
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div onMouseEnter={() => setOnEditHover(true)} onMouseLeave={() => setOnEditHover(false)}>
                        <EditIcon
                            data={item.id}
                            url={name === "offerLanderCampaigns" ? `#/campaign/edit/` : `#/templates/edit/`}
                            isBlank
                        />
                    </div>
                    <div onMouseEnter={() => setOnDeleteHover(true)} onMouseLeave={() => setOnDeleteHover(false)}>
                        <Icon
                            icon={IconLib.IconTrash}
                            color={isDarkMode || onDeleteHover ? "white" : "red"}
                            width={20}
                            onClick={() => removeHandler(item)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const calculateLockedWeightsTotal = (data) => {
    let total = 0;
    _.forOwn(data, (item, key) => {
        if (item.lock) {
            total = total + item.weight;
        }
    });

    return total;
};
const calculateOverrideWeightsTotal = (data) => {
    let total = 0;
    _.forOwn(data, (item, key) => {
        if (item.weightOverride) {
            total = total + item.weight;
        }
    });

    return total;
};
